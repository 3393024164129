import React, { useContext, useEffect } from "react";

import "./metabi.scss"

export function subscribeRPM(event) {
  //const json = JSON.parse(event);
  if (event.data != undefined) {
    try {
      let json = JSON.parse(event.data);
      console.log("FOUND EVENT DATA!!! " + event.data);
      if (json.eventName == 'v1.frame.ready') {
        const frame = document.getElementById('frame');
        frame.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**'
          }),
          '*'
        );
      }
      if (json.eventName === 'v1.avatar.exported') {
        console.log(`Avatar URL: ${json.data.url}`);
        //document.getElementById('avatarUrl').innerHTML = `Avatar URL: ${json.data.url}`;

        global.avatar_url = json.data.url + //"?quality=" + global.avatar_options.quality +
                            "?meshLod=" + global.avatar_options.meshLod +
                            "&textureSizeLimit=" + global.avatar_options.textureSizeLimit +
                            "&textureAtlas=" + global.avatar_options.textureAtlas +
                            "&textureChannels=" + global.avatar_options.textureChannels +
                            "&morphTargets=" + global.avatar_options.morphTargets +
                            "&pose=" + global.avatar_options.pose;
        
        document.getElementById('frame').hidden = true;
        //NOPE!!! Is there _any_ way to reach out and do this to a React component?? Dammit.
        //let rpmOptions = document.getElementById("RpmAvatarOptions");
        //if (rpmOptions != null) {
          //rpmOptions.components["RpmAvatarOptions"].setState({collapsed: true});
        //}
        /*  // Ah, haha, no reason to even get this complicated, yet. When we are ready to store the glb in our cloud, do this.
            //But for the first pass, just make an <a> anchor with the URL we want, and let the user download it manually.
        let req = new XMLHttpRequest();
        req.open("GET", json.data.url);
        req.responseType = "arrayBuffer";

        req.onload = function () {
          if (this.status == 200) {
            let uInt8Array = new Uint8Array(this.response);
            console.log("!!!!!!!!! downloaded it!  length = " + this.response.length);
          }

            //onDownloaded(this);
        };
        req.send();
        */
      }
    } catch(error) {
      console.log("Had an error: " + error);
      console.log("Json data: " + event.data);
    }
  }
  console.log("!!!!!!!!!!!!!! Got an RPM message!!!   name = " + JSON.stringify(event));
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export class RPMAvatarOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
    this.selectQuality = this.selectQuality.bind(this);
    this.selectMeshLod = this.selectMeshLod.bind(this);
    this.selectTextureSizeLimit = this.selectTextureSizeLimit.bind(this);
    this.selectTextureAtlas = this.selectTextureAtlas.bind(this);
    this.selectTextureChannels = this.selectTextureChannels.bind(this);
    this.selectMorphTargets = this.selectMorphTargets.bind(this);
    this.selectPose = this.selectPose.bind(this);

    this.avatarQualityRef = React.createRef();
    this.avatarMeshLodRef = React.createRef();
    this.avatarTextureSizeRef = React.createRef();
    this.avatarTextureAtlasRef = React.createRef();
    this.avatarTextureChannelRef = React.createRef();
    this.avatarMorphTargetRef = React.createRef();
    this.avatarPoseRef = React.createRef();

    global.avatar_options = {
      quality: "high",//redundant, not used
      meshLod: "0",
      textureSizeLimit: "512",
      textureAtlas: "none",
      textureChannels: "baseColor",
      morphTargets: "ARKit,Oculus Visemes,eyesClosed,mouthSmile,mouthOpen,eyesLookUp,eyesLookDown",
      pose: "A"
    };
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  selectQuality() {
    let val =  this.avatarQualityRef.current?.value;
    console.log("Quality selected: " + val);
    global.avatar_options.quality = val;
  }

  selectMeshLod() {
    let val =  this.avatarMeshLodRef.current?.value;
    console.log("MeshLod selected: " + val);
    global.avatar_options.meshLod = val;
  }

  selectTextureSizeLimit() {
    let val =  this.avatarTextureSizeRef.current?.value;
    console.log("TextureSize selected: " + val);
    global.avatar_options.textureSizeLimit = val;
  }

  selectTextureAtlas() {
    let val =  this.avatarTextureAtlasRef.current?.value;
    console.log("TextureAtlas selected: " + val);
    global.avatar_options.textureAtlas = val;
  }
  
  selectTextureChannels() {
    let val =  this.avatarTextureChannelRef.current?.value;
    console.log("TextureChannel selected: " + val);
    //global.avatar_options.textureChannels = val;
  }
  
  selectMorphTargets() {
    let val =  this.avatarMorphTargetRef.current?.value;
    console.log("MorphTarget selected: " + val);
    //global.avatar_options.morphTargets = val;
  }
  
  selectPose() {
    let val =  this.avatarPoseRef.current?.value;
    console.log("Pose selected: " + val);
    global.avatar_options.pose = val;
  }

  /**
            Quality
            &nbsp;&nbsp;
            <select style={{ color: "#002e5c" }} ref={ this.avatarQualityRef } onChange={ this.selectQuality }>
              <option key={ Math.random() } value="high">High</option>
              <option key={ Math.random() } value="medium">Medium</option>
              <option key={ Math.random() } value="low">Low</option>
            </select>
            &nbsp;&nbsp;

            
          <p>
            Texture Channels - FIX - Needs Checkboxes
            &nbsp;&nbsp;      
            <select style={{ color: "#002e5c" }} ref={ this.avatarTextureChannelRef } onChange={ this.selectTextureChannels }>
              <option key={ Math.random() } value="baseColor">baseColor</option>
              <option key={ Math.random() } value="normal">normal</option>
              <option key={ Math.random() } value="metallicRoughness">metallicRoughness</option>
              <option key={ Math.random() } value="emissive">emissive</option>
              <option key={ Math.random() } value="occlusion">occlusion</option>
              <option key={ Math.random() } value="none">none</option>
            </select>
          </p>
          <p>
            Morph Targets
            &nbsp;&nbsp;
            <select style={{ color: "#002e5c" }} ref={ this.avatarMorphTargetRef } onChange={ this.selectMorphTargets }>
              <option key={ Math.random() } value="Default">Default</option>
              <option key={ Math.random() } value="ARKit">ARKit</option>
              <option key={ Math.random() } value="Oculus Visemes">Oculus Visemes</option>
              <option key={ Math.random() } value="All">All</option>
            </select>
          </p>
  */

  render() {
    return (
      <div className="rpm-avatar-options-wrapper" >
        <button onClick={ this.toggleCollapsed }><img  src="https://production.shared-media-hubs.public.s3.amazonaws.com/assets/dropdown_arrow.png"/></button>
        &nbsp;&nbsp;
        Avatar Options
        <div className={"collapse" + (this.state.collapsed ? '' : 'in')}>
          <p>
            Mesh LOD
            &nbsp;&nbsp;
            <select style={{ color: "#002e5c" }} ref={ this.avatarMeshLodRef } onChange={ this.selectMeshLod }>
              <option key={ Math.random() } value="0">0 - 100%</option>
              <option key={ Math.random() } value="1">1 - 50%</option> 
              <option key={ Math.random() } value="2">2 - 25%</option>
            </select>
          </p>
          <p>
            Texture Size Limit
            &nbsp;&nbsp;
            <select style={{ color: "#002e5c" }} ref={ this.avatarTextureSizeRef } onChange={ this.selectTextureSizeLimit }>
              <option key={ Math.random() } value="256">256</option>
              <option key={ Math.random() } value="512">512</option>
              <option key={ Math.random() } value="1024">1024</option>
            </select>
          </p>
          <p>
            Texture Atlas
            &nbsp;&nbsp;
            <select style={{ color: "#002e5c" }} ref={ this.avatarTextureAtlasRef } onChange={ this.selectTextureAtlas }>
              <option key={ Math.random() } value="None">None</option>
              <option key={ Math.random() } value="256">256</option>
              <option key={ Math.random() } value="512">512</option>
              <option key={ Math.random() } value="1024">1024</option>
            </select>
          </p>
          <p>
            Pose
            &nbsp;&nbsp;
            <select style={{ color: "#002e5c" }} ref={ this.avatarPoseRef } onChange={ this.selectPose }>
              <option key={ Math.random() } value="A">A Pose</option>
              <option key={ Math.random() } value="T">T Pose</option>
            </select>
          </p>
        </div>
      </div>
    );
  }
}

export class RPMAvatarCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }
  
  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  
  render() {
    let frame_src = "https://metabi.readyplayer.me/avatar?frameApi";
    /*
    //Ah, WHOOPS!!! None of this goes here! This all goes in the .glb URL, when we download it. 
    frame_src += "&quality=" + global.avatar_options.quality;
    frame_src += "&meshLod=" + global.avatar_options.meshLod;
    frame_src += "&textureSizeLimit=" + global.avatar_options.textureSizeLimit;
    frame_src += "&textureAtlas=" + global.avatar_options.textureAtlas;
    frame_src += "&textureChannels=" + global.avatar_options.textureChannels;
    frame_src += "&morphTargets=" + global.avatar_options.morphTargets;
    frame_src += "&pose=" + global.avatar_options.pose;*/
    console.log("Frame source: " + frame_src);
    //   // what the hell? Not working here.
    return (
      <div id="avatarCreatorWrapper" className="rpm-avatar-creator-wrapper" >
        { (global.avatar_url != null) &&
            <center>
              <div className="avatar-download">
                <a className="red-anchor" href={global.avatar_url}>Download Avatar</a>
              </div>
            </center>
          }
        <button onClick={ this.toggleCollapsed }><img className="arrow" src="https://production.shared-media-hubs.public.s3.amazonaws.com/assets/dropdown_arrow.png"/></button>&nbsp;&nbsp;
        RPM Avatar Creator
        <div className={"collapse" + (this.state.collapsed ? '' : 'in')}>

          <RPMAvatarOptions  id="RpmAvatarOptions"/> 

          <iframe id="frame" 
            className="rpm-avatar-creator-frame" 
            allow="camera *; microphone *; clipboard-write" 
            src={frame_src}>
          </iframe>
        </div>
      </div>
    );
  }
}